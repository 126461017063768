import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import HomeLoansForm from "../../components/HomeLoansForm";
import loanImage from "../../assets/loans.png";

const HomeLoans = () => {
  return (
    <div>
      <Header />
      <img
        className="loans-form-image"
        src={loanImage}
        alt="A family moving into their new home."
      />
      <main>
        <HomeLoansForm />
      </main>

      <Footer />
    </div>
  );
};

export default HomeLoans;
