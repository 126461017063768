import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
// Findahome Forms/Components
import Contact from "./pages/Contact/index";
import HomeLoans from "./pages/HomeLoans";
import AgentPortal from "./pages/AgentPortal";
import Home from "./pages/Home/index";
import Search from "./pages/Search/index";
import AgentSearch from "./pages/AgentSearch";
import AgentProfile from "./pages/AgentProfile";
import AgentSignup from "./pages/AgentSignup";
import SignupSuccess from "./pages/SignupSuccess";
import LenderSignup from "./pages/LenderSignup";
import LenderSearch from "./pages/LenderSearch";
import LenderProfile from "./pages/LenderProfile";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/home-loans" element={<HomeLoans />} />
        <Route path="/find-an-agent" element={<AgentSearch />} />
        <Route path="/agent-profile/:id" element={<AgentProfile />} />
        <Route path="/agent-signup" element={<AgentSignup />} />
        <Route path="/signup-success" element={<SignupSuccess />} />
        <Route path="/search" element={<Search />} />
        <Route path="/user-login" element={<AgentPortal />} />
        <Route path="/lender-signup" element={<LenderSignup />} />
        <Route path="/find-a-lender" element={<LenderSearch />} />
        <Route path="/lender-profile/:id" element={<LenderProfile />} />

        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
