import React, { useState } from "react";
import "./agent-portal.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const AgentPortal = () => {
  const [isClosed, setIsClosed] = useState(false);

  // ---------------- MESSAGE CENTER -----------------
  window.onmessage = function (event) {
    // console.log(event.data);
    // Listens for listing link for opening the modal
    if (typeof event.data === "string" && event.data.includes("LoggedIn")) {
      // Hides Header/Footer
      setIsClosed(true);
    }
    if (typeof event.data === "string" && event.data.includes("Logout")) {
      // Redirect to Home
      window.location.href = "https://findahome.com/";
    }
    if (typeof event.data === "string" && event.data.includes("LogIn")) {
      // Redirect to Login page
      window.location.href = "https://findahome.com/user-login";
    }
    if (typeof event.data === "string" && event.data.includes("SignUp")) {
      // Redirect to Signup page
      window.location.href = "https://findahome.com/agent-signup";
    }
  };

  return (
    <div>
      <div className={isClosed === true ? "hidden" : ""}>
        <Header />
      </div>
      <div className={isClosed === true ? "modal-container" : ""}>
        <div>
          <iframe
            id="listing-integrations"
            src="https://www.findahomeinusa.com/fah2024/user-login"
            title="Listing search"
            style={{
              width: "100vw",
              height: "100vh",
              overflow: "auto",
              border: "none",
            }}
            className="portal-frame"
          ></iframe>
        </div>
      </div>

      <div className={isClosed === true ? "hidden" : ""}>
        <Footer />
      </div>
    </div>
  );
};

export default AgentPortal;
