import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ContactForm from "../../components/ContactForm";
import "./contact.css";

const Contact = () => {
  return (
    <div>
      <Header />

      <main>
        {/* Page Heading */}
        <div className="contact-form-heading">
          <h2>Contact Us</h2>
          <p>Fill out the form below and we'll be in contact tomorrow.</p>
          <hr className="contact-form-hr" />
        </div>

        {/* The Form */}
        <ContactForm />
      </main>

      <Footer />
    </div>
  );
};

export default Contact;
