import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import "./agentSignup.css";

const AgentSignup = () => {
  const navigate = useNavigate();

  // ---------------- MESSAGE CENTER -----------------
  window.onmessage = function (event) {
    // console.log(event.data);
    // Listens for listing link for opening the modal
    if (typeof event.data === "string" && event.data.includes("Success")) {
      navigate(`/signup-success`);
    }
  };

  return (
    <div>
      <Header />

      <main>
        <iframe
          id="listing-integrations"
          src="https://www.findahomeinusa.com/fahlsf2024"
          title="Listing search"
          className="fah-agent-signup-form"
          style={{
            width: "100%",
            height: "1600px",
            overflowX: "hidden",
            overflowY: "auto",
            border: "none",
          }}
        ></iframe>
      </main>

      <Footer />
    </div>
  );
};

export default AgentSignup;
